// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/UserProfilePhoto.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/UserProfilePhoto.tsx");
  import.meta.hot.lastModified = "1716561031848.921";
}
// REMIX HMR END

import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useOptionalUser } from "~/hooks/useUser";
import { cn } from "~/utils/cn";
export function UserProfilePhoto({
  className
}) {
  _s();
  const user = useOptionalUser();
  return <UserAvatar avatarUrl={user?.avatarUrl} name={user?.name} className={className} />;
}
_s(UserProfilePhoto, "gqXoXrwy0rMHgaN7Y1noFHoOsVs=", false, function () {
  return [useOptionalUser];
});
_c = UserProfilePhoto;
export function UserAvatar({
  avatarUrl,
  name,
  className
}) {
  return avatarUrl ? <div className={cn("grid aspect-square place-items-center", className)}>
      <img className={cn("aspect-square rounded-full p-[7%]")} src={avatarUrl} alt={name ?? "User"} />
    </div> : <UserCircleIcon className={cn("aspect-square text-charcoal-400", className)} />;
}
_c2 = UserAvatar;
var _c, _c2;
$RefreshReg$(_c, "UserProfilePhoto");
$RefreshReg$(_c2, "UserAvatar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;